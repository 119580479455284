import React from 'react';
import { createStyles, Divider, Drawer, IconButton, List, ListItem, ListItemText, Theme, WithStyles, withStyles, Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Axios from 'axios';
import { ALL_WEBHOOKS } from './Dashboard';

interface DashboardMenuProps extends WithStyles<typeof styles> {
  webhookNames: string[],
  webhookChoosed: string | null,
  onRefresh: (force?: boolean) => void,
  onSelect: (selected: string) => void,
}

class DashboardMenu extends React.Component<DashboardMenuProps> {

  clearData() {
    let path = '/webhook/';
    if (this.props.webhookChoosed !== null) {
      path += this.props.webhookChoosed;
    }
    Axios.delete(path).then(() => {
      this.props.onRefresh(true);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}>

        <div className={classes.appBarOffset} />
        <Box className={classes.iconsBox}>
          <IconButton className={classes.iconButton}
            onClick={() => this.clearData()}>
            <DeleteForeverIcon />
          </IconButton>

          <Divider orientation={'vertical'} />

          <IconButton className={classes.iconButton}
            onClick={() => this.props.onRefresh()}>
            <RefreshIcon />
          </IconButton>
        </Box>

        <Divider />

        <div className={classes.drawerContainer}>
          <List>
            {this.props.webhookNames.map((text) => (
              <ListItem button key={text}
                selected={this.props.webhookChoosed === text
                  || (this.props.webhookChoosed === null && text === ALL_WEBHOOKS)}>
                  <ListItemText primary={text} onClick={() => this.props.onSelect(text)}/>
                </ListItem>
              ))}
          </List>
        </div>
      </Drawer>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  drawer: {
    width: '10rem',
    height: '100%',
  },
  drawerPaper: {
    width: '10rem',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  appBarOffset: {
    height: theme.mixins.toolbar.height,
  },
  iconsBox: {
    display: 'flex',
  },
  iconButton: {
    // width: '50%',
    margin: '0 auto',
  },
});

export default withStyles(styles)(DashboardMenu);
