import { StateService } from '../StateService';
import Axios from 'axios';

export interface SecurityState {
  isLoggedIn: boolean,
  apiKey: string,
}

const STORAGE_KEY = "webhook-tester-security-state";

export class Security extends StateService<SecurityState> {

  constructor() {
    super(Security.getStateFromStorage());

    Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
    this.setApiKey(this.state.value.apiKey);
  }

  login(key: string): void {
    this.setApiKey(key);

    Axios.get('/auth').then(() => {
      const state = {
        isLoggedIn: true,
        apiKey: key,
      };
      this.setStateInStorage(state);
    }).catch(() => {
      this.logout();
    })
  }

  logout(): void {
    this.setApiKey('');
    const state = {
      isLoggedIn: false,
      apiKey: ''
    };
    this.setStateInStorage(state);
  }

  private setApiKey(apiKey: string): void {
    Axios.defaults.headers.common['Tester-Secret'] = apiKey;
  }

  private static getStateFromStorage(): SecurityState {
    const rawState = localStorage.getItem(STORAGE_KEY);
    if (rawState) {
      return JSON.parse(rawState) as SecurityState;
    }
    return {
        isLoggedIn: false,
        apiKey: '',
      }
  }

  private setStateInStorage(state: SecurityState): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    this.setState(state);
  }
}
