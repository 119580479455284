import React from 'react';
import { Grid, TextField, IconButton, createStyles, WithStyles, Theme, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

interface LoginFormProps extends WithStyles<typeof styles> {
  onSubmit: (apiKey: string) => void,
}

interface LoginFormState {
  key: string,
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

  constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      key: '',
    };
  }

  onKeyChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      key: event.target.value,
    });
  }

  render() {
    return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item xs={10}>
        <TextField
          color="secondary"
          className={this.props.classes.keyField}
          label="User key"
          value={this.state.key}
          onChange={(e) => this.onKeyChange(e)}
          type="password"
          autoComplete="current-password"
          />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          color="secondary"
          onClick={() => this.props.onSubmit(this.state.key)}>
          <CheckIcon />
        </IconButton>
      </Grid>
    </Grid>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  keyField: {
    width: '100%',
  },
});

export default withStyles(styles)(LoginForm);
