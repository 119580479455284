import React from 'react';
import { AppBar, Button, createStyles, IconButton, Theme, Toolbar, Typography, WithStyles, withStyles } from '@material-ui/core';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import { CoreContext } from '../service/core/CoreContext';
import LogoutButton from './navbar/LogoutButton';

interface NavbarProps extends WithStyles<typeof styles> {
  isLoggedIn: boolean,
  modeEnable: boolean,
  onModeChange: () => void,
}

class Navbar extends React.Component<NavbarProps> {
  context!: React.ContextType<typeof CoreContext>;

  renderLogout() {
    return (
      <LogoutButton onClick={() => this.context.security.logout() } />
    );
  }

  render() {
    return (
    <AppBar position="sticky" className={this.props.classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={this.props.classes.title}>
          Webhook tester
        </Typography>
        <IconButton
          className={this.props.classes.modeButton}
          onClick={this.props.onModeChange}
          name="Dark mode">
            <Brightness2Icon />
        </IconButton>
        {this.props.isLoggedIn &&
          <Button
          onClick={() => this.context.security.logout()}
          color="inherit">Logout</Button>
        }
      </Toolbar>
    </AppBar>
    );
  }
}
Navbar.contextType = CoreContext;

const styles = (theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  modeButton: (props: any) => ({
    color: props.modeEnable
      ? theme.palette.secondary.light
      : theme.palette.grey[200]
  }),
  title: {
    flexGrow: 1,
  },
});

export default withStyles(styles)(Navbar);
