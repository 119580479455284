import React, { createContext } from 'react';
import { Security } from './Security';

export interface CoreService {
  security: Security,
}

interface CoreProps {
  security?: Security,
}

export const CoreContext = createContext({} as CoreService);

export class CoreProvider extends React.Component<CoreProps> {

  render() {
    const value = {
      security: this.props.security || new Security(),
    };

    return (
      <CoreContext.Provider value={value}>
        {this.props.children}
      </CoreContext.Provider>
    )
  }
}
