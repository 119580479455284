import React from 'react';
import { WebhookData } from '../model/WebhookData';
import { TableRow, TableCell, IconButton, Collapse, Box, createStyles, WithStyles, withStyles, List, ListItem, ListItemText, Grid, Divider } from '@material-ui/core';
import { DASHBOARD_LIST_COLUMNS } from './DashboardList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface WebhookDetailsProps extends WithStyles<typeof styles> {
  data: WebhookData,
}

interface WebhookDetailsState {
  open: boolean
}

class WebhookDetails extends React.Component<WebhookDetailsProps, WebhookDetailsState> {

  constructor(props: WebhookDetailsProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleCollapse() {
    this.setState({
      open: !this.state.open,
    });
  }

  tryFormatBody(body: string): string {
    try {
      return JSON.stringify(JSON.parse(body), null, 2);
    } catch {}
    return body;
  }

  render() {
    const { data, classes } = this.props;
    return (
      <React.Fragment>
        <TableRow key={'resume'} selected={this.state.open}>
          {DASHBOARD_LIST_COLUMNS.map(col => {
            return (
              <React.Fragment key={col.id}>
                {col.id === 'collapse' && (
                  <TableCell>
                    <IconButton size="small" onClick={() => this.handleCollapse()}>
                      {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                )}
                {col.id !== 'collapse' && (
                  <TableCell
                    align="left"
                    style={{minWidth: `${col.minWidth}rem`}}>
                    { col.format(data) }
                  </TableCell>
                )}
              </React.Fragment>
            );
          })}
        </TableRow>
        <TableRow key={'details'}>
          <TableCell key={'details-cell'}
            className={classes.collapsableCell} colSpan={6}>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Box className={classes.collapsableBox}>
                <Grid container>
                  <Grid item xs={7}>
                    <div className={classes.detailsTitle}>Body</div>
                    <Divider />
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.detailsTitle}>Headers</div>
                  </Grid>
                  <Grid item xs={7} className={classes.overflowable}>
                    <pre>
                      <code>
                        {this.tryFormatBody(data.body)}
                      </code>
                    </pre>
                  </Grid>
                  <Grid item xs={5} className={classes.overflowable}>
                    <List dense>
                      {Array.from(data.headers).map(([header, value]) => {
                        return (
                          <ListItem key={header}>
                            <ListItemText>
                              {header + ':\t' + value.join(', ')}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

const styles = createStyles({
  collapsableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  collapsableBox: {
    margin: 1,
    minHeight: '20rem',
    maxHeight: '20rem',
    display: 'flex',
  },
  overflowable: {
    overflow: 'auto',
    maxHeight: '17.5rem',
  },
  detailsTitle: {
    height: '2rem',
    paddingTop: '0.5rem',
    paddingLeft: '1rem',
  },
});

export default withStyles(styles)(WebhookDetails);
