import { BehaviorSubject } from 'rxjs';

/**
 * Used to have service with shareable state for `React.Component`
 * Based on RxJS BehaviorSubject to handle the state
 *
 */
export class StateService<T> {
    private subject: BehaviorSubject<T>;

    constructor(state: T) {
        this.subject = new BehaviorSubject(state);
    }

    get state() {
        return this.subject;
    }

    protected setState(state: T) {
        this.subject.next(state);
    }
}
