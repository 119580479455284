import { createMuiTheme, createStyles, CssBaseline, Theme, ThemeProvider, WithStyles, withStyles } from '@material-ui/core';
import { blueGrey, cyan, indigo } from '@material-ui/core/colors';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import GuardedRoute from './GuardRoute';
import Navbar from './layout/Navbar';
import Login from './login/Login';
import { CoreContext } from './service/core/CoreContext';
import { SecurityState } from './service/core/Security';

interface AppProps extends WithStyles<typeof styles> {
}

interface AppState extends SecurityState {
  darkModeEnable: boolean,
}

class App extends React.Component<AppProps, AppState> {

  static contextType = CoreContext;
  context!: React.ContextType<typeof CoreContext>

  constructor(props: AppProps) {
    super(props);
    this.state = {
      darkModeEnable: true,
      isLoggedIn: false,
      apiKey: '',
    };
  }

  componentDidMount() {
    this.context.security.state.subscribe({
      next: (securityState: SecurityState) => {
        this.setState({
          ...securityState,
        })
      },
    })
  }

  componentWillUnmount() {
    this.context.security.state.unsubscribe();
  }

  handleModeChange() {
    this.setState({
      darkModeEnable: !this.state.darkModeEnable,
    });
  }

  render() {
    const theme = createMuiTheme({
      palette: {
        type: this.state.darkModeEnable ? 'dark' : 'light',
        primary: {
          main: blueGrey[500]
        },
        secondary: {
          main: this.state.darkModeEnable ? cyan[500] : indigo[500],
        },
      },
      mixins: {
        toolbar: {
          height: '6vh',
        }
      }
    });

    return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar
        isLoggedIn={this.state.isLoggedIn}
        modeEnable={this.state.darkModeEnable}
        onModeChange={() => this.handleModeChange()} />
      <div className={this.props.classes.contentContainer}>
        <Switch>
          <GuardedRoute path="/dashboard">
            <Dashboard />
          </GuardedRoute>
          <Route path={['/', '/login']}>
            <Login />
          </Route>
        </Switch>
        {this.state.isLoggedIn && (<Redirect to="/dashboard" />)}
      </div>
    </ThemeProvider>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  contentContainer: {
    height: '94vh !important',
  },
});

export default withStyles(styles)(App);
