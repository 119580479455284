import { createStyles, Grid, Paper, Theme, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import LoginForm from './LoginForm';
import { CoreContext } from '../service/core/CoreContext';

interface LoginProps extends WithStyles<typeof styles> {
}

class Login extends React.Component<LoginProps> {

  context!: React.ContextType<typeof CoreContext>

  render() {
    return (
    <Grid container alignItems="center" justify="center" className={this.props.classes.root} >
      <Grid item xs={4} >
        <Paper elevation={4} className={this.props.classes.paper}>
          <LoginForm onSubmit={(apiKey) => this.context.security.login(apiKey)}/>
        </Paper>
      </Grid>
    </Grid>
    );
  }
}
Login.contextType = CoreContext;

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
});

export default withStyles(styles)(Login);
