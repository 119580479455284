import React from 'react';
import DashboardMenu from './DashboardMenu';
import DashboardList from './DashboardList';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import Axios from 'axios';

export const ALL_WEBHOOKS: string = 'All webhooks';

interface DashboardProps extends WithStyles<typeof styles> {}

interface DashboardState {
  webhookNames: string[],
  webhookChoosed: string | null,
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      webhookNames: [],
      webhookChoosed: null,
    }
  }

  componentDidMount() {
    this.getWebhookNames();
  }

  getWebhookNames(force?: boolean) {
    Axios.get<string[]>('/webhook/names').then((resp) => {
      resp.data.sort();
      this.setState({
        webhookNames: resp.data,
        webhookChoosed: force !== undefined && force ? null : this.state.webhookChoosed,
      });
    });
  }

  chooseWebhook(selected: string) {
    this.setState({
      webhookChoosed: selected === ALL_WEBHOOKS ? null : selected,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.dashboard}>
          <DashboardMenu
            webhookNames={[ALL_WEBHOOKS].concat(this.state.webhookNames)}
            webhookChoosed={this.state.webhookChoosed}
            onRefresh={(force?: boolean) => this.getWebhookNames(force)}
            onSelect={(selected: string) => this.chooseWebhook(selected)} />
          <DashboardList
            webhookNames={this.state.webhookChoosed
              ? [this.state.webhookChoosed]
              : this.state.webhookNames}/>
        </div>
      </React.Fragment>
    );
  }
}

const styles = createStyles({
  dashboard: {
    display: 'flex',
  },
});

export default withStyles(styles)(Dashboard);
