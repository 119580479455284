import React from 'react';
import { Button } from '@material-ui/core';

interface LogoutButtonProps {
  onClick: () => void,
}

const LogoutButton = (props: LogoutButtonProps) => {
  return (
    <Button
      onClick={props.onClick}
      color="inherit">Logout</Button>
  );
}

export default LogoutButton;
