import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { CoreContext } from './service/core/CoreContext';

class GuardedRoute extends React.Component<RouteProps> {
  static contextType = CoreContext;
  context!: React.ContextType<typeof CoreContext>;

  render() {
    const {children, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={() => {
          return this.context.security.state.value.isLoggedIn
            ? (children)
            : (<Redirect to="/login" />)
        }} />
    );
  }
}

export default GuardedRoute;
